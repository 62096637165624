.filter-hover:hover {
	color: #ff6600!important;
}

@keyframes expandAnimation {
	0% {
		opacity: 0;
		transform: rotateX(70deg);
		transform-origin: top;
	}

	100% {
		opacity: 1;
		transform: rotateX(0deg);
		transform-origin: top;
	}
}